import React, { useState, FunctionComponent } from 'react';
import {
  Typography,
  Button,
  Box,
  Select,
  TextField,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  Checkbox,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ReactComponent as SearchIcon } from '../../images/audience/search-icon.svg';
import { IInsightStore, IWorkspaceTheme } from '../../types/insight';

import { useStyles as selectStyles } from '../spotlight/Spotlight.style';
import { sentimentQuery } from '../spotlight/constants';
import { allEmotions, allSources } from '../../static/insightData';
import { capitalizeFirstLetter, setSourceTitle } from '../../utils/stringUtil';
import _ from 'lodash';

interface QueryProps {
  insightStore: IInsightStore;
  setInsightStore: React.Dispatch<React.SetStateAction<IInsightStore>>;
  openFilter: boolean;
  setOpenFilter: React.Dispatch<React.SetStateAction<boolean>>;
  themes: Array<IWorkspaceTheme>;
  searchString: string;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  onClickHandler: () => {};
}

export const FilterOptions: FunctionComponent<QueryProps> = ({
  insightStore,
  setInsightStore,
  openFilter,
  setOpenFilter,
  themes,
  setSearchString,
  searchString,
  onClickHandler,
}) => {
  const classes = selectStyles();

  const [tempInsightStore, setTempInsightStore] = useState({ ...insightStore });
  const { sentiment_store, theme_store, emotion_store, source } = tempInsightStore;
  const [activeTag, setActiveTag] = useState<number>(0);

  const handleClose = (e: any, default_close: boolean) => {
    if (!default_close) {
      if (e && e.target && e.target.className) {
        if (!e.target.className.includes('MuiBackdrop-root')) {
          return;
        }
      }
    }
    setOpenFilter(false);
    setSearchString('');
  };

  let filtered_themes = themes;

  if (searchString && searchString.trim() && searchString.trim().length > 0) {
    filtered_themes = themes.filter(t => t.theme_name.toLowerCase().includes(searchString.trim().toLowerCase()));
  }

  if (sentiment_store.length > 0) {
    const sentiments = sentiment_store.map(s => s.value);
    filtered_themes = filtered_themes.filter(t => sentiments.includes(t.sentiment));
  }

  if (emotion_store.length > 0) {
    const emotions = emotion_store.map(s => s.value);

    filtered_themes = filtered_themes.filter(f => {
      const intersection_themes = emotions.filter(e => f.tags.includes(e));
      return intersection_themes.length > 0;
    });
  }

  const filterValue = (field: string, label: string, value: string) => {
    if (field === 'sentiment') {
      const check_value = sentiment_store.find(s => s.value === value);
      let modified_value = sentiment_store;
      if (check_value) {
        modified_value = sentiment_store.filter(s => s.value !== value);
      } else {
        modified_value.push({ value, label });
      }

      setTempInsightStore({ theme_store, emotion_store, source, sentiment_store: modified_value });
    }

    if (field === 'theme') {
      const check_value = theme_store.find(s => s.value === value);
      let modified_value = theme_store;
      if (check_value) {
        modified_value = theme_store.filter(s => s.value !== value);
      } else {
        modified_value.push({ value });
      }

      setTempInsightStore({ sentiment_store, emotion_store, source, theme_store: modified_value });
    }

    if (field === 'emotion') {
      const check_value = emotion_store.find(s => s.value === value);
      let modified_value = emotion_store;
      if (check_value) {
        modified_value = emotion_store.filter(s => s.value !== value);
      } else {
        modified_value.push({ value, label: value });
      }

      setTempInsightStore({ sentiment_store, theme_store, source, emotion_store: modified_value });
    }

    if (field === 'source') {
      if (source === value) {
        setTempInsightStore({ sentiment_store, theme_store, emotion_store, source: '' });
      } else {
        setTempInsightStore({ sentiment_store, theme_store, emotion_store, source: value });
      }
    }
  };

  const selectAllThemes = () => {
    if (activeTag === 0) {
      const all_values = themes.map(t => {
        return { value: t.theme_name };
      });
      setTempInsightStore({ sentiment_store, emotion_store, source, theme_store: all_values });
    }

    if (activeTag === 1) {
      setTempInsightStore({ sentiment_store: sentimentQuery, emotion_store, source, theme_store });
    }

    if (activeTag === 2) {
      const all_values = allEmotions.map(t => {
        return { value: t, label: capitalizeFirstLetter(t) };
      });
      setTempInsightStore({ sentiment_store, emotion_store: all_values, source, theme_store });
    }
  };

  const filterBottomSection = (activeTag: number) => {
    return (
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mx={1}>
        {activeTag !== 3 ? (
          <Typography
            variant="subtitle2"
            fontWeight={700}
            className={classes.selectAllBox}
            display={'inline-block'}
            style={{ height: 'fit-content', marginLeft: 18 }}
            onClick={() => {
              selectAllThemes();
            }}
          >
            Select All
          </Typography>
        ) : (
          <Typography></Typography>
        )}
        <Button
          variant="contained"
          className={classes.applyButton}
          onClick={e => {
            setInsightStore(tempInsightStore);
            onClickHandler();
            handleClose(e, true);
          }}
        >
          <Typography fontSize={13} fontWeight={700} textAlign="right">
            Apply
          </Typography>
        </Button>
      </Box>
    );
  };

  return (
    <Select
      variant="outlined"
      className={classes.activeSelectField}
      style={{ visibility: 'hidden', width: 1, marginTop: -80 }}
      renderValue={() => (
        <Typography variant="subtitle1" color={'primary.contrastText'}>
          Text
        </Typography>
      )}
      value={''}
      open={openFilter}
      onClose={e => handleClose(e, false)}
      onClick={e => e.stopPropagation()}
    >
      <Box display={'flex'} mt={-1}>
        <Box width={'40%'}>
          <Box
            px={2}
            py={1.25}
            style={{
              borderBottom: '1px solid #eae9f0',
              cursor: 'pointer',
              backgroundColor: activeTag === 0 ? 'rgba(57, 84, 255, 0.08)' : 'white',
            }}
            onClick={() => setActiveTag(0)}
          >
            <Typography variant="subtitle1" fontWeight={'bold'}>
              Topics
            </Typography>
          </Box>

          <Box
            px={2}
            py={1.25}
            style={{
              borderBottom: '1px solid #eae9f0',
              cursor: 'pointer',
              backgroundColor: activeTag === 1 ? 'rgba(57, 84, 255, 0.08)' : 'white',
            }}
            onClick={() => setActiveTag(1)}
          >
            <Typography variant="subtitle1" fontWeight={'bold'}>
              Topic Sentiment
            </Typography>
          </Box>

          <Box
            px={2}
            py={1.25}
            style={{
              borderBottom: '1px solid #eae9f0',
              cursor: 'pointer',
              backgroundColor: activeTag === 2 ? 'rgba(57, 84, 255, 0.08)' : 'white',
            }}
            onClick={() => setActiveTag(2)}
          >
            <Typography variant="subtitle1" fontWeight={'bold'}>
              Dominant Emotions
            </Typography>
          </Box>

          <Box
            px={2}
            py={1.25}
            style={{
              borderBottom: '1px solid #eae9f0',
              cursor: 'pointer',
              backgroundColor: activeTag === 3 ? 'rgba(57, 84, 255, 0.08)' : 'white',
            }}
            onClick={() => setActiveTag(3)}
          >
            <Typography variant="subtitle1" fontWeight={'bold'}>
              Source
            </Typography>
          </Box>
        </Box>

        {activeTag === 0 && (
          <Box
            width={'60%'}
            style={{ borderLeft: '1px solid #E5E5E5' }}
            pt={1}
            display={'flex'}
            flexDirection={'column'}
            height={'400px'}
            justifyContent={'space-between'}
          >
            <Box>
              <TextField
                fullWidth
                placeholder="Search for theme keyword"
                className={classes.searchTextField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={e => {
                  setSearchString(e.target.value);
                }}
              />

              <Box style={{ height: 300, overflowY: 'auto' }}>
                <Box display={'flex'} alignItems={'center'} justifyContent="space-between" mx={1}>
                  <List className={classes.searchList} style={{ paddingBottom: 50 }}>
                    {filtered_themes.map(
                      (item, idx) =>
                        item &&
                        item.theme_name && (
                          <ListItemButton key={idx} dense onClick={() => filterValue('theme', '', item.theme_name)}>
                            <ListItemIcon>
                              <Checkbox
                                icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
                                checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
                                classes={{
                                  root: classes.check,
                                  checked: classes.checked,
                                }}
                                checked={theme_store.some(t => t.value === item.theme_name)}
                              />
                            </ListItemIcon>

                            <Typography variant="subtitle1">{item.theme_name}</Typography>
                          </ListItemButton>
                        ),
                    )}
                  </List>
                </Box>
              </Box>
            </Box>
            {filterBottomSection(activeTag)}
          </Box>
        )}

        {activeTag === 1 && (
          <Box
            width={'400px'}
            style={{ borderLeft: '1px solid #E5E5E5' }}
            pt={4}
            display={'flex'}
            flexDirection={'column'}
            height={'400px'}
            justifyContent={'space-between'}
          >
            <Box>
              {sentimentQuery.map(t => (
                <Box
                  ml={2}
                  mb={0.7}
                  style={{ cursor: 'pointer' }}
                  display={'flex'}
                  onClick={() => filterValue('sentiment', t.label, t.value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
                      checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
                      classes={{
                        root: classes.check,
                        checked: classes.checked,
                      }}
                      checked={sentiment_store.some(s => s.value === t.value)}
                    />
                  </ListItemIcon>
                  <Typography variant="subtitle1" ml={-1}>
                    {t.label}
                  </Typography>
                </Box>
              ))}
            </Box>
            {filterBottomSection(activeTag)}
          </Box>
        )}

        {activeTag === 2 && (
          <Box
            width={'400px'}
            style={{ borderLeft: '1px solid #E5E5E5', overflowY: 'auto' }}
            pt={4}
            display={'flex'}
            flexDirection={'column'}
            height={'400px'}
            justifyContent={'space-between'}
          >
            <Box style={{ height: 340, overflowY: 'auto' }}>
              {allEmotions.map(t => (
                <Box
                  display={'flex'}
                  ml={2}
                  mb={0.7}
                  style={{ cursor: 'pointer' }}
                  onClick={() => filterValue('emotion', t, t)}
                >
                  <ListItemIcon>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
                      checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
                      classes={{
                        root: classes.check,
                        checked: classes.checked,
                      }}
                      checked={emotion_store.some(s => s.value === t)}
                    />
                  </ListItemIcon>
                  <Typography variant="subtitle1" ml={-1}>
                    {capitalizeFirstLetter(t)}
                  </Typography>
                </Box>
              ))}
            </Box>
            {filterBottomSection(activeTag)}
          </Box>
        )}
        {activeTag === 3 && (
          <Box
            width={'400px'}
            style={{ borderLeft: '1px solid #E5E5E5', overflowY: 'auto' }}
            pt={4}
            display={'flex'}
            flexDirection={'column'}
            height={'400px'}
            justifyContent={'space-between'}
          >
            <Box>
              {allSources.map(t => (
                <Box
                  display={'flex'}
                  ml={2}
                  mb={0.7}
                  style={{ cursor: 'pointer' }}
                  onClick={() => filterValue('source', t, t)}
                >
                  <ListItemIcon>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
                      checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
                      classes={{
                        root: classes.check,
                        checked: classes.checked,
                      }}
                      checked={source === t}
                    />
                  </ListItemIcon>
                  <Typography variant="subtitle1" ml={-1}>
                    {setSourceTitle(t)}
                  </Typography>
                </Box>
              ))}
            </Box>
            {filterBottomSection(activeTag)}
          </Box>
        )}
      </Box>
    </Select>
  );
};
