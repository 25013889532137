export const allLanguages = {
  ar: 'Arabic',
  bn: 'Bengali',
  fr: 'French',
  de: 'German',
  hi: 'Hindi',
  gu: 'Gujarati',
  id: 'Indonesian',
  ja: 'Japanese',
  kn: 'Kannada',
  ko: 'Korean',
  ml: 'Malayalam',
  mr: 'Marathi',
  pt: 'Portuguese',
  ru: 'Russian',
  es: 'Spanish',
  sv: 'Swedish',
  ta: 'Tamil',
  te: 'Telugu',
  uz: 'Uzbek',
};

export const allLanguagesForSurvey = {
  en: 'English',
  ...allLanguages,
};
