import { useLocation } from 'react-router-dom';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { modifiedDate } from './DateUtil';
import CONFIG from '../config';
import { rtlLanguages } from '../static/staticData';
import helpImg from '../images/intent/help.svg';
import complaintImg from '../images/intent/complaint.svg';
import improvementImg from '../images/intent/improvement.svg';
import praiseImg from '../images/intent/praise.svg';

const DEFAULT_USER_IMG = 'https://cdn.blitzllama.com/default/default-img.svg';

export const userLastSeen = (last_active_at: string, is_verified: boolean) => {
  if (!is_verified) {
    return 'Not yet joined';
  }
  if (!last_active_at) return '-';
  const dateDiffFromNow = Math.abs(moment(last_active_at).diff(moment().format('Y-MM-DD'), 'days'));
  if (dateDiffFromNow === 0) return 'today';
  else if (dateDiffFromNow === 1) return `${dateDiffFromNow} day ago`;
  else return `${dateDiffFromNow} days ago`;
};

export const userRole = (member_role: string, is_primary: boolean) => {
  if (is_primary) return 'Primary admin';
  else if (member_role === 'admin') return 'Admin';
  else return 'Member';
};

export const userImgFormat = (name: string, userImg: string) => {
  const isImg = userImg ? true : false;
  if (!name) return { name: '', bgColor: '', userImg: DEFAULT_USER_IMG, isImg: true };
  name = name.toUpperCase();
  const nameArr = name.split(' ');
  const firstStr = nameArr[0];
  let secondStr = '';
  if (nameArr.length > 1) {
    secondStr = nameArr[1];
  }
  let fullName = firstStr[0];
  if (secondStr !== '') {
    fullName = fullName + secondStr[0];
  }
  const asciiValue = firstStr.charCodeAt(0) - 64;
  let bgColor = '#FFAB7B';
  if (asciiValue <= 6) {
    bgColor = '#FF97AA';
  } else if (asciiValue <= 12) {
    bgColor = '#AC97FF';
  } else if (asciiValue <= 18) {
    bgColor = '#6CCCE1';
  }

  return { name: fullName, bgColor, userImg, isImg };
};

export const intentImgFormat = (intent: string) => {
  if (intent.toLowerCase() === 'praise') {
    return praiseImg;
  } else if (intent.toLowerCase() === 'improvement') {
    return improvementImg;
  } else if (intent.toLowerCase() === 'complaint') {
    return complaintImg;
  } else if (intent.toLowerCase() === 'help') {
    return helpImg;
  } else {
    return '';
  }
};

export const isValidSelector = (selector: string) => {
  try {
    document.createDocumentFragment().querySelector(selector);
  } catch (err) {
    return false;
  }
  return true;
};

export const isValidRegex = (selector: string) => {
  let isValid = true;
  try {
    new RegExp(selector);
  } catch (e) {
    isValid = false;
  }
  return isValid;
};

export const getAttributeLabel = (attribute: string) => {
  if (!attribute) {
    return '-';
  }

  const attr_label_obj = { created_at: 'Created at', total_days_on_app: 'Days since creation', user_id: 'User Id' };
  if (attr_label_obj[attribute]) {
    return attr_label_obj[attribute];
  }

  return attribute;
};

export const getAttributeValue = (attribute: string, value: any) => {
  if (!attribute) {
    return '-';
  }

  if (attribute === 'total_days_on_app') {
    return value;
  }

  if (typeof value === 'boolean') {
    return value.toString();
  }

  if (!value) {
    return '-';
  }

  if (attribute === 'created_at' || attribute === 'Date') {
    return modifiedDate(value, 'lll');
  }

  // if (value && value.includes(':00+00:00')) {
  //   return value.replace(':00+00:00', '');
  // }

  return value.toString();
};

export const numberWithCommas = x => {
  if (!x) return x;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const currPlanDetails = (curr_month_responses: number) => {
  switch (true) {
    case curr_month_responses <= 5000:
      return { label: '5K', value: 5000, price: 85 };
    case curr_month_responses <= 10000:
      return { label: '10K', value: 10000, price: 170 };
    case curr_month_responses <= 20000:
      return { label: '20K', value: 20000, price: 275 };
    case curr_month_responses <= 50000:
      return { label: '50K', value: 50000, price: 425 };
    case curr_month_responses <= 75000:
      return { label: '75K', value: 75000, price: 850 };
    case curr_month_responses <= 100000:
      return { label: '100K', value: 100000, price: 1065 };
    default:
      return { label: '', value: 1, price: 0 };
  }
};

export const getPlatformText = (platform: string) => {
  if (platform === 'hybrid') {
    return 'React native';
  } else if (platform === 'all') {
    return '';
  } else {
    return platform;
  }
};

export const surveyLinkUrl = (
  link_url: string,
  lang: string,
  prototype = false,
  preview = false,
  custom_domain = '',
) => {
  return `${custom_domain ? custom_domain : CONFIG.survey_link}${link_url}${prototype ? '/prototype' : ''}${lang &&
    '?lang=' + lang}${preview ? '&preview=' + preview : ''}`;
};

export function isValidUrl(str: string) {
  if (!str) return true;
  const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (regexp.test(str)) {
    return true;
  } else {
    return false;
  }
}

export const getPrototypeLink = (link: string) => {
  if (link.includes('figma.com/embed')) {
    return link;
  }

  if (link.includes('xd.adobe.com/embed')) {
    return link;
  }

  if (link.includes('figma.com')) {
    return `https://www.figma.com/embed?embed_host=share&url=${link}`;
  }

  if (link.includes('xd.adobe.com/view')) {
    return link.replace('xd.adobe.com/view', 'xd.adobe.com/embed');
  }

  if (!link.startsWith('http')) {
    return `http://${link}`;
  }

  return link;
};

export const isValidImageURL = (str: string) => {
  if (typeof str !== 'string') return false;
  return !!str.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp|svg)$/gi);
};

function ASCIISum(array: string) {
  let sum = 0;
  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < array[i].length; j++) {
      const char_code = array[i].charCodeAt(j);
      sum += char_code;
    }
  }
  return sum;
}

export function getImageId(str: string, max: number) {
  if (!str) return 1;
  return (ASCIISum(str) % max) + 1;
}

export const isValidSubdomain = (domain: string) => {
  return /^[a-z0-9-]+$/i.test(domain);
};

export const isRtl = (lang: string) => {
  return rtlLanguages.includes(lang);
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function getProtocol() {
  return window.location.href.includes('dashboard.') ? 'https' : 'http';
}

export const formattedSurveyFrequency = (str: string) => {
  const new_str = str.replace(/_/g, ' ');
  return new_str.charAt(0).toUpperCase() + new_str.slice(1);
};

export const responseDurationTxt = (time: number) => {
  if (!time) {
    return '0';
  }

  if (time < 1000) {
    return '< 1s';
  }

  return Math.round(time / 1000) + 's';
};

export const copyBoardLink = (board_url: string) => {
  return copy(`${window.location.origin}/report/${board_url}`);
};

export const isValidCtaURL = (str: string) => {
  const res = str.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  );
  return res !== null;
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const setSourceTitle = (str: string) => {
  if (str === 'quick_text') {
    return 'Paste Text';
  }

  if (str === 'csv') {
    return 'CSV';
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function isArray(o) {
  return Object.prototype.toString.call(o) === '[object Array]';
}
