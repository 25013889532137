import React, { FunctionComponent, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Dialog,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  CircularProgress,
  TableBody,
  LinearProgress,
} from '@mui/material';
import deleteImg from '../../images/delete-icon.svg';
import { ColoredPill, insightStyles } from './InsightStyle';
import { TextArea } from '../../styles/customStyles';
import { textChunking, uploadQuickText } from '../../services/insightService';
import AlertUtil from '../../utils/AlertUtil';
import { IChunkItem } from '../../types/insight';
import { StyledTableRow } from '../common/tableStyles';

export const QuickTextModal: FunctionComponent<{
  open: boolean;
  toggleModal: (value: boolean) => void;
}> = ({ open, toggleModal }) => {
  const classes = insightStyles();
  const [sentence, setSentence] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [isInsightLoading, setInsightLoading] = useState(false);
  const [chunks, setChunks] = useState<Array<IChunkItem>>([]);
  const [step, setStep] = useState<number>(1);
  const [disableGenerate, setDisableGenerate] = useState(false);
  const [promptPercent, setPromptPercent] = useState(0);

  const clickHandler = async () => {
    const results = await textChunking({ sentence, user_name: userName });
    setInsightLoading(true);
    setDisableGenerate(true);

    let curr_percent = 5;
    const timer_id = setInterval(() => {
      curr_percent += 11;
      setPromptPercent(curr_percent);
      if (curr_percent >= 93) {
        clearInterval(timer_id);
      }
    }, 1000);

    if (results && results.data) {
      setStep(2);
      setChunks(results.data);
    }

    setPromptPercent(0);
    setDisableGenerate(false);
    setInsightLoading(false);
  };

  const uploadChunks = async () => {
    const results = await uploadQuickText({ chunks, user_name: userName });
    if (results && results.data) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Uploaded Successfully',
      });
      window.location.href = '/respository';
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <div>
      <Dialog open={true} fullWidth maxWidth={'md'}>
        <Grid container p={4}>
          <Box display="flex" alignItems="center">
            <Typography fontWeight={'bold'}>Paste Text</Typography>
          </Box>
          <img
            src={deleteImg}
            alt=""
            style={{ marginLeft: 'auto', height: 32, cursor: 'pointer' }}
            onClick={() => {
              toggleModal(false);
            }}
          />

          <Grid item lg={12}>
            <Typography fontWeight={'bold'} variant={'subtitle1'} mb={1.5}>
              Run a one-time analysis
              <span style={{ fontWeight: 'normal', display: 'block', marginTop: -8 }}>
                (insert raw conversation data below)
              </span>
            </Typography>
            {step === 1 && (
              <Box>
                <TextArea
                  minRows={8}
                  style={{ width: '100%', marginBottom: 10, fontFamily: 'rustica, sans-serif' }}
                  placeholder={'Insert/ past text here...'}
                  onChange={e => setSentence(e.target.value)}
                />
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Box display={'flex'} flexDirection={'column'} width={'80%'}>
                    <Typography fontWeight={'bold'} variant={'subtitle1'} mb={1.5}>
                      Person identifiers
                      <span style={{ fontWeight: 'normal', display: 'block', marginTop: -8 }}>
                        (users / interviewer etc.,)
                      </span>
                    </Typography>
                    <TextField
                      variant="outlined"
                      className={classes.userIdField}
                      placeholder={'Type name...'}
                      style={{ width: '50%' }}
                      onChange={e => setUserName(e.target.value)}
                    />
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      className={classes.applyButton}
                      onClick={e => {
                        clickHandler();
                      }}
                    >
                      <Typography fontSize={13} fontWeight={700}>
                        Submit & analyze (1/2)
                      </Typography>
                    </Button>
                    {disableGenerate && (
                      <Box
                        display="flex"
                        alignItems="flex-end"
                        flexDirection={'column'}
                        justifyContent={'flex-end'}
                        mt={1}
                      >
                        <div style={{ width: '200px' }}>
                          <LinearProgress variant="determinate" value={promptPercent} className={classes.progress} />
                        </div>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
            {step === 2 && (
              <Box>
                <TableContainer>
                  <Table className={classes.previewTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell scope="row">
                          <Typography style={{ paddingLeft: 10, fontWeight: 'bold' }} noWrap>
                            #id
                          </Typography>
                        </TableCell>
                        <TableCell scope="row">
                          <Typography style={{ paddingLeft: 10, fontWeight: 'bold' }} noWrap>
                            Content
                          </Typography>
                        </TableCell>
                        <TableCell scope="row">
                          <Box display={'flex'}>
                            <Typography style={{ paddingLeft: 10, fontWeight: 'bold', marginRight: 5 }} noWrap>
                              Emotion
                            </Typography>
                            {isInsightLoading && (
                              <CircularProgress className={classes.loading} size={16} style={{ marginTop: 5 }} />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell scope="row">
                          <Box display={'flex'}>
                            <Typography style={{ paddingLeft: 10, fontWeight: 'bold', marginRight: 5 }} noWrap>
                              Sentiment
                            </Typography>
                            {isInsightLoading && (
                              <CircularProgress className={classes.loading} size={16} style={{ marginTop: 5 }} />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {chunks.map((item, idx) => (
                        <StyledTableRow key={idx}>
                          <TableCell scope="row" width={'10%'}>
                            <Typography style={{ paddingLeft: 10 }}>{+item.uniq_id + 1}</Typography>
                          </TableCell>
                          <TableCell scope="row" width={'70%'}>
                            <Typography style={{ paddingLeft: 10 }} flexWrap={'wrap'}>
                              {item.content}
                            </Typography>
                          </TableCell>
                          <TableCell width={'10%'}>
                            <Box>{item.emotion && <ColoredPill sentiment={item.emotion} add_strong={false} />}</Box>
                          </TableCell>
                          <TableCell width={'10%'}>
                            <Box display={'flex'} flexWrap={'wrap'}>
                              {item.sentiment && <ColoredPill sentiment={item.sentiment} add_strong={false} />}
                            </Box>
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box display={'flex'} justifyContent={'flex-end'}>
                  <Button
                    variant="contained"
                    className={classes.applyButton}
                    onClick={e => {
                      uploadChunks();
                    }}
                  >
                    <Typography fontSize={13} fontWeight={700}>
                      Merge Data (2/2)
                    </Typography>
                  </Button>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};
